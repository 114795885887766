import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./CarouselProducts.scss";
import { NavLink } from 'react-router-dom';



const CustomRightArrow = ({ onClick }) => {
    return <button className="carousel-arrow right" onClick={() => onClick()} ><img src="/img/right_arrow.png" alt="Freccia a destra" /></button>;
};

const CustomLeftArrow = ({ onClick }) => {
    return <button className="carousel-arrow left" onClick={() => onClick()} ><img src="/img/left_arrow.png" alt="Freccia a sinistra" /></button>;
};

const productList = [
    {
        slug: 'muffin-super-pepite-cioccolato-fondente',
        name: 'Muffin super con pepite di cioccolato fondente',
        pack: 'muffin_super-pack.png',
        product: 'muffin_super.png',
        color1: "#B9EFFF",
        color2: "#B7ECFC"
    },
    {
        slug: 'muffin-proteico-caramello-salato',
        name: 'Muffin Protein al caramello salato',
        pack: 'muffin_proteico-pack.png',
        product: 'muffin_proteico.png',
        color1: "#89b73b",
        color2: "#c1e781"
    },
    {
        slug: 'muffin-super-al-cacao',
        name: 'Muffin super al cacao con crema di cioccolato',
        pack: 'muffin_cioccolato-pack.png',
        product: 'muffin_cioccolato.png',
        color1: "#B9EFFF",
        color2: "#B7ECFC"
    },
    {
        slug: 'muffin-senza-glutine-cioccolato-fondente',
        name: 'Muffin Senza Glutine',
        pack: 'muffin_senza_glutine-pack.png',
        product: 'muffin_senza_glutine.png',
        color1: "#B9EFFF",
        color2: "#B7ECFC"
    },
    {
        slug: 'ciambella-vaniglia',
        name: 'Ciambelle con vaniglia naturale da bacche',
        pack: 'ciambella_vaniglia-pack.png',
        product: 'ciambella_vaniglia.png',
        color1: "#B9EFFF",
        color2: "#B7ECFC"
    },
    {
        slug: 'ciambella-crema-al-cioccolato',
        name: 'Ciambelle con crema al cioccolato',
        pack: 'ciambella_cioccolato-pack.png',
        product: 'ciambella_cioccolato.png',
        color1: "#B9EFFF",
        color2: "#B7ECFC"
    },
    {
        slug: "plumcake-goccia-di-miele",
        name: 'Plumcake con una goccia di miele',
        pack: 'plumcake-pack.png',
        product: 'plumcake.png',
        color1: "#B9EFFF",
        color2: "#B7ECFC"
    },
    {
        slug: "brownies-al-cioccolato",
        name: 'Brownies al cioccolato',
        pack: 'brownies-pack.png',
        product: 'brownies.png',
        color1: "#B9EFFF",
        color2: "#B7ECFC"
    }
]

function CarouselProducts(props){
    let title=props.title;
    let slug=props.slug;
    return(
        <section className="skin" id='products'>
            <h2 dangerouslySetInnerHTML={{__html:title}} />
            <Slider
            dots={false}
            infinite={true}
            speed={500}
            slidesToShow={3}
            slidesToScroll={1}
            className="carousel-slider"
            centerMode={true}
            centerPadding='12%'
            draggable={true}
            nextArrow={<CustomRightArrow />}
            prevArrow={<CustomLeftArrow />}
            responsive={[
                {
                    breakpoint: 950,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '30%'
                    }
                },
                {
                    breakpoint: 620,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '0'
                    }
                }
            ]}
            >
                {productList.map((el, idx) => {
                    if (el.slug!=slug){
                        return(
                            <div key={idx} className="item-content">
                                <div className="pack-detail-container">
                                    <div className="pack-detail" style={{ backgroundImage: 'url(/img/slider_item_background.svg)' }}>
                                        <img src={`/img/carousel/${el.pack}`} alt={'Confezione ' + el.name} />
                                        <div className='product_data'>
                                            <p>{el.name}</p>
                                            <NavLink to={"/" + el.slug} onClick={() => window.scrollTo(0, 0)}>Scopri ora</NavLink>
                                        </div>
                            </div>
                        </div>
                        <div className="item-image-container">
                            <img className="item-image" src={`/img/carousel/${el.product}`} alt={el.name} />
                        </div>
                        <svg className="pod" width="397" height="196" viewBox="0 0 397 196" fill="none" xmlns="http://www.w3.org/2000/svg" id={"ombra_" + idx}>
                            <g>
                                <g className="Ellisse 10">
                                    <path className="Vector" d="M198.5 196C308.129 196 397 182.121 397 165C397 147.879 308.129 134 198.5 134C88.8715 134 0 147.879 0 165C0 182.121 88.8715 196 198.5 196Z" fill="#083BB3" />
                                    <path className="Vector_2" d="M198.5 195.25C307.714 195.25 396.25 181.707 396.25 165C396.25 148.293 307.714 134.75 198.5 134.75C89.2857 134.75 0.75 148.293 0.75 165C0.75 181.707 89.2857 195.25 198.5 195.25Z" stroke="#6B799B" strokeWidth="1.5" />
                                </g>
                                <g className="Ellisse 11">
                                    <path className="Vector_3" d="M198.5 191C300.949 191 384 178.24 384 162.5C384 146.76 300.949 134 198.5 134C96.0512 134 13 146.76 13 162.5C13 178.24 96.0512 191 198.5 191Z" fill="#083BB3" />
                                    <path className="Vector_4" d="M198.5 189.5C300.12 189.5 382.5 177.412 382.5 162.5C382.5 147.588 300.12 135.5 198.5 135.5C96.8796 135.5 14.5 147.588 14.5 162.5C14.5 177.412 96.8796 189.5 198.5 189.5Z" stroke="#072B80" strokeWidth="3" />
                                </g>
                                <g className="Ellisse 12">
                                    <path className="Vector_5" d="M196.5 186C289.008 186 364 175.255 364 162C364 148.745 289.008 138 196.5 138C103.992 138 29 148.745 29 162C29 175.255 103.992 186 196.5 186Z" fill="#7b9afb" stroke="#9ba3fa" strokeWidth="3" />
                                    <path className="Vector_6" d="M196.5 185.25C288.593 185.25 363.25 174.841 363.25 162C363.25 149.159 288.593 138.75 196.5 138.75C104.407 138.75 29.75 149.159 29.75 162C29.75 174.841 104.407 185.25 196.5 185.25Z" stroke="#072B80" strokeWidth="1.5" />
                                </g>
                                <g className="light">
                                    <path className="Ellisse 13" d="M196 178C279.395 178 347 171.06 347 162.5C347 153.94 279.395 147 196 147C112.605 147 45 153.94 45 162.5C45 171.06 112.605 178 196 178Z" fill="#B9EFFF" />
                                    <path className="Unione 2" d="M45.077 163H45L25 0H367L347 163H346.923C344.353 171.33 277.766 178 196 178C114.234 178 47.647 171.33 45.077 163Z" fill={`url(#paint${idx}_linear)`} />
                                </g>
                            </g>
                            <defs>
                                <linearGradient id={`paint${idx}_linear`} x1="196" y1="0" x2="196" y2="178" gradientUnits="userSpaceOnUse">
                                    <stop stopColor={el.color1} stopOpacity="0" />
                                    <stop offset="1" stopColor={el.color1} />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                )}
                })}
        </Slider></section>
    )
}
export default CarouselProducts