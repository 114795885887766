import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import './Product.scss';
import Eye from "../../components/Eye/Eye";
import CarouselProducts from "../../components/CarouselProducts/CarouselProducts";
import { Scrollbar } from 'react-scrollbars-custom';
import { useEffect, useState, useRef } from 'react';



function Product(props) {
    const containerWidth = useRef(null);
    const [tableWidth, setTableWidth] = useState();
    const [windowWidth, setWindowWidth] = useState();
    useEffect(() => {
        setTableWidth(containerWidth.current.offsetWidth);
    }, [windowWidth]);
    useEffect(() => {
        setWindowWidth(window.innerWidth)
        window.addEventListener('resize', (e) => {
            setWindowWidth(window.innerWidth)
        })
    }, []);
    let nome_prodotto=props.name;
    let list_products={
        "muffin-super-al-cacao":{
            "logo": "logo-muffin-super",
            "mainimg": "/img/products/muffin-super-cacao-big-bg.png",
            "title": "MUFFIN<br/>SUPER<br/>AL CACAO",
            "subhead": "con crema al cioccolato",
            "intro_title": "SALI DI LIVELLO<br/>CON UN CLASSICO<br/>AVVOLGENTE<br/>E SUPER SOFFICE",
            "intro_text": "<p><b>Ingredienti</b>: Ripieno crema al cioccolato 23% (sciroppo di glucosio, acqua, zucchero, cioccolato 10% (zucchero, cacao* magro, burro di cacao*), destrosio, olio di semi di girasole, aromi, stabilizzante: pectina; sale), farina di <b>FRUMENTO</b>, zucchero, <b>UOVA</b> fresche pastorizzate italiane da galline allevate a terra 17,3%, olio di semi di girasole alto oleico, cacao in polvere* 2.9%, sciroppo di glucosio, emulsionante: mono e digliceridi degli acidi grassi, lecitina di girasole; aromi, agenti lievitanti: difosfato disodico, carbonato acido di sodio, carbonato acido di ammonio; cioccolato fondente 1% (zucchero, pasta di cacao*, burro di cacao*, emulsionanti: lecitine di girasole; aroma), sale, addensante: gomma di guar.<br/><b>Può contenere SOIA e SENAPE</b></p><p>*Certificato Rainforest Alliance. ra.org<br/><b>Senza lattosio. Senza proteine del latte. Senza olio di palma.</b></p>",
            "nut_val": `<tr><th>Dichiarazione nutrizionale</th><th>Valori medi per 100g</th><th>Valori medi per porzione 50g (1 pezzo)</th></tr>
                <tr><td>Energia</td><td>Kjoule 1821</td><td>Kjoule 911</td></tr>
                <tr><td></td><td>Kcal 435</td><td>Kcal 218</td></tr>
                <tr><td>Grassi</td><td>g 22.0</td><td>g 11</td></tr>
                <tr><td>Grassi saturi</td><td>g 3.0</td><td>g 1.5</td></tr>
                <tr><td>Carboidrati</td><td>g 51.0</td><td>g 26</td></tr>
                <tr><td>Zuccheri</td><td>g 27.0</td><td>g 14</td></tr>
                <tr><td>Fibre</td><td>g 1.7</td><td>g 0.9</td></tr>
                <tr><td>Proteine</td><td>g 5.2</td><td>g 2.6</td></tr>
                <tr><td>Sale</td><td>g 0.64</td><td>g 0.32</td></tr>
            `,
            "tabledecoration": "arancio",
            "pesoconfezione": "300",
            "numeropezzi": "6"
        },
        "muffin-senza-glutine-cioccolato-fondente":{
            "logo": "logo-muffin-senza-glutine",
            "mainimg": "/img/products/muffin-senza-glutine-cioccolato-fondente-bg.png",
            "title": "MUFFIN Senza Glutine",
            "subhead": "Con pepite di cioccolato fondente",
            "intro_title": "BUONO <br class='mobile'/>COME SEMPRE,<br/>RICCO <br class='mobile'/>DI CIOCCOLATO, <br class='mobile'/>PER FARE MATCHMAKING<br/>E AFFRONTARE<br/>OGNI PARTITA",
            "intro_text": "<p><b>Ingredienti</b>: <b>UOVA</b> fresche pastorizzate italiane da galline allevate a terra 23,9%, zucchero, olio di semi di girasole alto oleico, amido di mais, sciroppo di glucosio, cioccolato fondente 9,5% (pasta di cacao, zucchero, burro di cacao, emulsionante: lecitina di girasole; aroma), fecola di patate, farina di riso, aromi, emulsionanti: mono- e digliceridi degli acidi grassi, lecitina di girasole; agenti lievitanti: difosfato disodico, carbonato acido di sodio, carbonato acido di ammonio; sale, addensante: gomma di guar.<br/><b>Può contenere SOIA</b>.</p><p class='small-notes'>Senza olio di palma. Prodotto specificatamente formulato per persone intolleranti al glutine. Senza glutine.</p>",
            "nut_val": `<tr><th>Dichiarazione nutrizionale</th><th>Valori medi per 100g</th><th>Valori medi per porzione 42g (1 pezzo)</th></tr>
                <tr><td>Energia</td><td>Kjoule 1783</td><td>Kjoule 749</td></tr>
                <tr><td></td><td>Kcal 426</td><td>Kcal 179</td></tr>
                <tr><td>Grassi</td><td>g 22.0</td><td>g 9.2</td></tr>
                <tr><td>Grassi saturi</td><td>g 4.5</td><td>g 1.9</td></tr>
                <tr><td>Carboidrati</td><td>g 53.0</td><td>g 22</td></tr>
                <tr><td>Zuccheri</td><td>g 28.0</td><td>g 12</td></tr>
                <tr><td>Fibre</td><td>g 0.9</td><td>g 0.4</td></tr>
                <tr><td>Proteine</td><td>g 3.6</td><td>g 1.5</td></tr>
                <tr><td>Sale</td><td>g 0.91</td><td>g 0.38</td></tr>
            `,
            "tabledecoration": "verde",
            "pesoconfezione": "252",
            "numeropezzi": "6"
        },
        "ciambella-vaniglia":{
            "logo": "logo-ciambella",
            "mainimg": "/img/products/ciambella-vaniglia-bg.png",
            "title": "CIAMBELLA",
            "subhead": "Con vaniglia naturale da bacche",
            "intro_title": "HAI GIÀ PERSO<br/>AL PRIMO ROUND?<br/>DISTRUGGI I BOT<br/>CON UNA PAUSA<br/>TOP!",
            "intro_text": "<p><b>Ingredienti</b>: Farina di <b>FRUMENTO</b>, zucchero, <b>UOVA</b> fresche pastorizzate italiane da galline allevate a terra 16,6%, oli e grassi vegetali (olio di semi di girasole, olio di cocco, burro di cacao), semilavorato di decoro 3,8% (destrosio, zucchero 10%, amido di <b>FRUMENTO</b>, burro di cacao, aroma), sciroppo di glucosio, miele, aromi, agenti lievitanti: difosfato disodico, carbonato acido di sodio, carbonato acido di ammonio; emulsionanti: mono- e digliceridi degli acidi grassi, lecitina di girasole; sale, aroma naturale di vaniglia da bacche 0,05%.<br/><b>Può contenere SOIA e SENAPE</b>.</p><p><b>Senza lattosio. Senza proteine del latte. Senza olio di palma.</b></p>",
            "nut_val": `<tr><th>Dichiarazione nutrizionale</th><th>Valori medi per 100g</th><th>Valori medi per porzione 40g (1 pezzo)</th></tr>
                <tr><td>Energia</td><td>Kjoule 1767</td><td>Kjoule 707</td></tr>
                <tr><td></td><td>Kcal 422</td><td>Kcal 169</td></tr>
                <tr><td>Grassi</td><td>g 19.0</td><td>g 7.6</td></tr>
                <tr><td>Grassi saturi</td><td>g 5.1</td><td>g 2.0</td></tr>
                <tr><td>Carboidrati</td><td>g 55.0</td><td>g 22</td></tr>
                <tr><td>Zuccheri</td><td>g 28.0</td><td>g 11</td></tr>
                <tr><td>Fibre</td><td>g 1.2</td><td>g 0.5</td></tr>
                <tr><td>Proteine</td><td>g 6.2</td><td>g 2.5</td></tr>
                <tr><td>Sale</td><td>g 1.10</td><td>g 0.44</td></tr>
            `,
            "tabledecoration": "blu",
            "pesoconfezione": "320",
            "numeropezzi": "8"
        },
        "ciambella-crema-al-cioccolato":{
            "logo": "logo-ciambella",
            "mainimg": "/img/products/ciambella-crema-al-cioccolato-bg.png",
            "title": "CIAMBELLA",
            "subhead": "Con crema al cioccolato",
            "intro_title": "EVITA DI TILTARE:<br/>SE TI SENTI NERFATO<br/>BOOSTA <br class='mobile'/>LA TUA MERENDA<br/>CON UN RIPIENO UNICO",
            "intro_text": "<p><b>Ingredienti</b>: Farina di <b>FRUMENTO</b>, crema al cioccolato 23% (sciroppo di glucosio, acqua, zucchero, cioccolato 10% (zucchero, cacao* magro, burro di cacao*), destrosio, olio di semi di girasole, stabilizzante: pectina; sale), zucchero, <b>UOVA</b> fresche pastorizzate italiane da galline allevate a terra 14,6%, oli e grassi vegetali (olio di semi di girasole, olio di cocco, burro di cacao*), semilavorato di decoro 2% (destrosio, zucchero 10%, amido di <b>FRUMENTO</b>, burro di cacao*, aroma), sciroppo di glucosio, miele, aromi, agenti lievitanti: difosfato disodico, carbonato acido di sodio, carbonato acido di ammonio; aromi, emulsionanti: mono e digliceridi degli acidi grassi, lecitina di girasole; sale.<br/><b>Può contenere SOIA e SENAPE</b>.</p><p>*Certificato Rainforest Alliance. ra.org<br/><b>Senza lattosio. Senza proteine del latte. Senza olio di palma.</b></p>",
            "nut_val": `<tr><th>Dichiarazione nutrizionale</th><th>Valori medi per 100g</th><th>Valori medi per porzione 50g (1 pezzo)</th></tr>
                <tr><td>Energia</td><td>Kjoule 1674</td><td>Kjoule 837</td></tr>
                <tr><td></td><td>Kcal 399</td><td>Kcal 200</td></tr>
                <tr><td>Grassi</td><td>g 15.0</td><td>g 7.5</td></tr>
                <tr><td>Grassi saturi</td><td>g 3.6</td><td>g 1.8</td></tr>
                <tr><td>Carboidrati</td><td>g 57.0</td><td>g 29</td></tr>
                <tr><td>Zuccheri</td><td>g 27.0</td><td>g 14.0</td></tr>
                <tr><td>Fibre</td><td>g 1.0</td><td>g 0.5</td></tr>
                <tr><td>Proteine</td><td>g 5.4</td><td>g 2.7</td></tr>
                <tr><td>Sale</td><td>g 2.7</td><td>g 0.50</td></tr>
            `,
            "tabledecoration": "blu",
            "pesoconfezione": "300",
            "numeropezzi": "6"
        },
        "plumcake-goccia-di-miele":{
            "logo": "logo-plumcake",
            "mainimg": "/img/products/plumcake-goccia-di-miele-bg.png",
            "title": "PLUMCAKE",
            "subhead": "Con una goccia di miele",
            "intro_title": "RICARICARSI <br class='mobile'/>CON GUSTO<br/>SENZA TRYHARDARE:<br/>IL PLATFORM<br />DELLE MERENDE TORNA<br/>PIÙ MORBIDO <br class='mobile'>CHE MAI",
            "intro_text": "<p><b>Ingredienti</b>: Farina di <b>FRUMENTO</b>, zucchero, <b>UOVA</b> fresche pastorizzate italiane da galline allevate a terra 16,2%, olio di semi di girasole alto oleico, miele 2%, sciroppo di glucosio, agenti lievitanti: difosfato disodico, carbonato acido di sodio, carbonato acido di ammonio; aromi, emulsionanti: mono e digliceridi degli acidi grassi, lecitina di girasole; sale.<br/><b>Può contenere SOIA e SENAPE</b>.</p><p><b>Senza lattosio. Senza proteine del latte. Senza olio di palma.</b></p>",
            "nut_val": `<tr><th>Dichiarazione nutrizionale</th><th>Valori medi per 100g</th><th>Valori medi per porzione 31,7g (1 pezzo)</th></tr>
                <tr><td>Energia</td><td>Kjoule 1726</td><td>Kjoule 547</td></tr>
                <tr><td></td><td>Kcal 412</td><td>Kcal 131</td></tr>
                <tr><td>Grassi</td><td>g 19.0</td><td>g 6.0</td></tr>
                <tr><td>Grassi saturi</td><td>g 2.8</td><td>g 0.9</td></tr>
                <tr><td>Carboidrati</td><td>g 53.0</td><td>g 17</td></tr>
                <tr><td>Zuccheri</td><td>g 28.0</td><td>g 8.9</td></tr>
                <tr><td>Fibre</td><td>g 1.3</td><td>g 0.4</td></tr>
                <tr><td>Proteine</td><td>g 6.0</td><td>g 1.9</td></tr>
                <tr><td>Sale</td><td>g 1.10</td><td>g 0.35</td></tr>
            `,
            "tabledecoration": "arancio",
            "pesoconfezione": "190",
            "numeropezzi": "6"
        },
        "muffin-super-pepite-cioccolato-fondente":{
            "logo": "logo-muffin-super",
            "mainimg": "/img/products/muffin-super-pepite-cioccolato-fondente-bg.png",
            "title": "MUFFIN SUPER",
            "subhead": "con pepite di cioccolato fondente",
            "intro_title": "IL POWER UP GUSTOSO<br/>E CIOCCOLATOSO<br/>PER QUELLE GIORNATE<br/>CHE SEMBRANO<br/>SPEEDRUN",
            "intro_text": "<p><b>Ingredienti</b>: Farina di <b>FRUMENTO</b>, zucchero, <b>UOVA</b> fresche pastorizzate italiane da galline allevate a terra 18,6%, olio di semi di girasole alto oleico, sciroppo di glucosio, cioccolato fondente 8,7% (zucchero, pasta di cacao*, burro di cacao*, emulsionante: lecitina di girasole; aroma), agenti lievitanti: difosfato disodico, carbonato acido di sodio, carbonato acido di ammonio; emulsionanti: mono- e digliceridi degli acidi grassi, lecitina di girasole; aromi, addensante: gomma di guar; sale.<br/><b> Può contenere SOIA e SENAPE.</b></p><p>*Certificato Rainforest Alliance. ra.org <br/><b>Senza lattosio. Senza proteine del latte. Senza olio di palma</b></p>",
            "nut_val": `
            <tr><th>Dichiarazione nutrizionale</th><th>Valori medi per 100g</th><th>Valori medi per porzione 50g (1 pezzo)</th></tr>
                <tr><td>Energia</td><td>Kjoule 1819</td><td>Kjoule 910</td></tr>
                <tr><td></td><td>Kcal 435</td><td>Kcal 218</td></tr>
                <tr><td>Grassi</td><td>g 23.0</td><td>g 12</td></tr>
                <tr><td>Grassi saturi</td><td>g 4.1</td><td>g 2.1</td></tr>
                <tr><td>Carboidrati</td><td>g 50.0</td><td>g 25</td></tr>
                <tr><td>Zuccheri</td><td>g 28.0</td><td>g 14</td></tr>
                <tr><td>Fibre</td><td>g 1.6</td><td>g 0.8</td></tr>
                <tr><td>Proteine</td><td>g 5.6</td><td>g 2.8</td></tr>
                <tr><td>Sale</td><td>g 0.83</td><td>g 0.42</td></tr>
            `,
            "tabledecoration": "arancio",
            "pesoconfezione": "300",
            "numeropezzi": "6"
        },
        "brownies-al-cioccolato": {
            "logo": "logo-brownies",
            "mainimg": "/img/products/brownies-al-cioccolato.png",
            "title": "BROWNIES",
            "subhead": "Al cioccolato",
            "intro_title": "LA GOAT MERENDA<br/>CHE FA DA CHECKPOINT<br/>A TUTTE LE TUE SFIDE:<br/>UNA PAUSA DOLCE E SOFFICE PER LE TUE SFIDE PIÙ HC!",
            "intro_text": "<p><b>Ingredienti</b>: Zucchero, farina di <b>FRUMENTO</b>, cioccolato fondente 18,4% (zucchero, pasta di cacao *, burro di cacao, emulsionante: lecitina(girasole); aroma naturale), <b>UOVA</b> fresche pastorizzate italiane da galline allevate a terra 16,3%, oli vegetali(olio di semi di girasole, olio di cocco), sciroppo di glucosio, cacao* in polvere 3.5%, emulsionante: mono - e digliceridi degli acidi grassi; aromi, destrosio, agenti lievitanti: difosfato disodico, carbonato acido di sodio, carbonato acido di ammonio; sale, stabilizzante: pectina. <br/>Può contenere <b>SOIA</b> e <b>SENAPE</b>.</p><p>*Certificato Rainforest Alliance. ra.org <br/><b>Senza lattosio. Senza Proteine del latte. Senza olio di Palma.</b></p>",
            "nut_val": `
            <tr><th>Dichiarazione nutrizionale</th><th>Valori medi per 100g</th><th>Valori medi per porzione 35g (1 pezzo)</th></tr>
                <tr><td>Energia</td><td>Kjoule 1763</td><td>Kjoule 617</td></tr>
                <tr><td></td><td>Kcal 421</td><td>Kcal 147</td></tr>
                <tr><td>Grassi</td><td>g 21.0</td><td>g 7.4</td></tr>
                <tr><td>Grassi saturi</td><td>g 11.0</td><td>g 3.9</td></tr>
                <tr><td>Carboidrati</td><td>g 51.0</td><td>g 18</td></tr>
                <tr><td>Zuccheri</td><td>g 30.0</td><td>g 11</td></tr>
                <tr><td>Fibre</td><td>g 2.8</td><td>g 1.0</td></tr>
                <tr><td>Proteine</td><td>g 5.7</td><td>g 2.0</td></tr>
                <tr><td>Sale</td><td>g 0.59</td><td>g 0.21</td></tr>
            `,
            "tabledecoration": "arancio",
            "pesoconfezione": "210",
            "numeropezzi": "6"
        },
        "muffin-proteico-caramello-salato":{
            "logo": "logo-muffin-protein",
            "mainimg": "/img/products/muffin-proteico-caramello-salato-bg.png",
            "title": "MUFFIN PROTEICO",
            "subhead": "con ripieno di caramello salato",
            "intro_title": "IL GUSTOSO RIPIENO<br/>CHE TI SERVE<br/>PER NON QUITTARE",
            "intro_text": "<p><b>Ingredienti</b>: Crema al caramello salato 20% (sciroppo di glucosio, zucchero, acqua, <b>LATTE</b> intero in polvere, <b>PANNA</b> in polvere, <b>BURRO</b>, aromi naturali, zucchero caramellizzato 0,6% corrispondente al 0,12% sul prodotto finito, gelificante: pectina; sale), farina di <b>FRUMENTO</b>, olio di semi di girasole alto oleico, zucchero, <b>UOVA</b> fresche pastorizzate italiane da galline allevate a terra 12,1%, proteine di pisello 6.5%, glutine di <b>FRUMENTO</b>, sciroppo di glucosio, zucchero caramellizzato 1,5%, aromi, emulsionanti: mono- e digliceridi degli acidi grassi, lecitina di girasole; agenti lievitanti: difosfato disodico, carbonato acido di sodio, carbonato acido di ammonio; sale.<br/><b>Può contenere SOIA e SENAPE</b>.</p><p><b>Senza olio di palma. Fonte di proteine.</b></p>",
            "nut_val": `
            <tr><th>Dichiarazione nutrizionale</th><th>Valori medi per 100g</th><th>Valori medi per porzione 42g (1 pezzo)</th></tr>
                <tr><td>Energia</td><td>Kjoule 1741</td><td>Kjoule 731</td></tr>
                <tr><td></td><td>Kcal 416</td><td>Kcal 175</td></tr>
                <tr><td>Grassi</td><td>g 20.0</td><td>g 8.4</td></tr>
                <tr><td>Grassi saturi</td><td>g 3.4</td><td>g 1.4</td></tr>
                <tr><td>Carboidrati</td><td>g 46.0</td><td>g 19</td></tr>
                <tr><td>Zuccheri</td><td>g 25.0</td><td>g 11</td></tr>
                <tr><td>Fibre</td><td>g 0.8</td><td>g 0.3</td></tr>
                <tr><td>Proteine</td><td>g 13.0</td><td>g 5.5</td></tr>
                <tr><td>Sale</td><td>g 0.83</td><td>g 0.35</td></tr>
            `,
            "tabledecoration": "verde",
            "pesoconfezione": "252",
            "numeropezzi": "6"
        }
           
    }
    
        return (
            <div className={'product-page ' +nome_prodotto}>
                <Header logo={`/img/logo/${list_products[nome_prodotto].logo}.png`}/>
                <section id='intro_product' style={{
                    backgroundImage: `url(${list_products[nome_prodotto].mainimg})`
                    }}>
                    <div className='container'>
                        <div id='product_info'>
                            <h1 dangerouslySetInnerHTML={{ __html: list_products[nome_prodotto].title }}/>
                            <h2>{list_products[nome_prodotto].subhead}</h2>
                            <h3 dangerouslySetInnerHTML={{ __html: list_products[nome_prodotto].intro_title }}/>
                            <div dangerouslySetInnerHTML={{__html: list_products[nome_prodotto].intro_text}} />
                        </div>
                    </div>
                </section>
                <div className='toptable'>
                    <Eye></Eye>
                    <img src={`/img/products/${list_products[nome_prodotto].tabledecoration}-top.svg`} />
                    
                </div>
                <div id='nutritional_facts'>
                    <div className='container' ref={containerWidth}>
                        <Scrollbar style={{ width: tableWidth, height: 532 }}>
                        <table dangerouslySetInnerHTML={{ __html: list_products[nome_prodotto].nut_val }} />
                        </Scrollbar>
                        <div id='infopack'>
                            Peso netto della confezione: {list_products[nome_prodotto].pesoconfezione} g.<br/>
                            La confezione contiene {list_products[nome_prodotto].numeropezzi} pezzi.
                        </div>
                    </div>
                </div>
                <div className='bottomtable'>
                    <img src={`/img/products/${list_products[nome_prodotto].tabledecoration}-bottom.svg`} />
                </div>
                <CarouselProducts title="SCEGLI<br/>UN'ALTRA SKIN" slug={nome_prodotto} />
                <Footer />
            </div>
        );
  
}
export default Product;