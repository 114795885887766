import { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import './Cookie.scss';
import Header from '../../components/Header/Header';

function Cookie() {

  useEffect(()=>{

    let script = document.createElement('script');
    script.src = "https://consent.cookiebot.com/737a56e4-2dc1-4e4d-9e82-13b2de58047b/cd.js";
    script.id = "CookieDeclaration";
    script.type = "text/javascript";
    script.async = true;
    document.getElementById('cookie-content').appendChild(script);

    return () => {
      if (document.getElementById('cookie-content')) {
        document.getElementById('cookie-content').removeChild(script)
      }
    }
    
  }, []);

  return (
    <div className="cookie_policy">
      <Header logo="/img/logo.png" />
      <div className='container'>
        <h1>Informativa cookies</h1>
        <p>
          Con la presente informativa Vicenzi S.p.A. intende fornire adeguate indicazioni in merito alle tecnologie che consentono a questo Sito Web di raggiungere gli scopi descritti di seguito.
        </p>
        <p>
          Tali tecnologie, di seguito definite quali “Strumenti di Tracciamento” permettono al Titolare di raccogliere e salvare informazioni (per esempio tramite l’utilizzo di Cookie) o di utilizzare risorse (per esempio eseguendo uno script) sul dispositivo dell’Utente quando quest’ultimo interagisce con questo Sito Web.<br/>
          Alcune delle finalità per le quali vengono impiegati Strumenti di Tracciamento potrebbero richiedere il consenso dell’Utente.<br/>
          Questo Sito Web utilizza Strumenti di Tracciamento gestiti direttamente dal Titolare (c.d. “di prima parte”) e Strumenti di Tracciamento che abilitano servizi forniti da terzi (c.d. “di terza parte”); salva diversa precisazione, tali terzi hanno accesso ai rispettivi Strumenti di Tracciamento.<br />
          La durata e il tempo di conservazione dei Cookie e degli altri Strumenti di Tracciamento è indicata nella tabella allegata al presente documento e può variare a seconda di quanto impostato dal Titolare o da ciascun fornitore terzo.
        </p>
        <p>
          Oltre a quanto già precisato nella descrizione di ciascuna delle categorie di seguito riportate, gli Utenti possono ottenere informazioni più dettagliate ed aggiornate sulla durata, così come qualsiasi altra informazione rilevante
          </p>
          <ul>
          <li>quale la presenza di altri Strumenti di Tracciamento</li>
          <li>nelle privacy policy dei rispettivi fornitori terzi (tramite i link messi a disposizione) o contattando il Titolare.</li>
          </ul>
        <h3>COME GESTIRE LE PREFERENZE E PRESTARE O REVOCARE IL CONSENSO</h3>
        <p>
          Esistono vari modi per gestire le preferenze relative agli Strumenti di Tracciamento e per prestare o revocare il consenso, ove necessario:<br />
          Gli Utenti possono gestire le preferenze relative agli Strumenti di Tracciamento direttamente tramite le impostazioni dei propri dispositivi – per esempio, possono impedire l’uso o l’archiviazione di Strumenti di Tracciamento.<br />
          In aggiunta, ogni qualvolta l’utilizzo di Strumenti di Tracciamento dipenda da consenso, l’Utente può prestare o revocare tale consenso impostando le proprie preferenze all’interno dell’informativa sui cookie o aggiornando tali preferenze tramite il widget delle impostazioni di tracciamento, se presente.<br />
          Grazie ad apposite funzioni del browser o del dispositivo è anche possibile rimuovere Strumenti di Tracciamento precedentemente salvati.<br />
          Altri Strumenti di Tracciamento presenti nella memoria locale del browser possono essere rimossi cancellando la cronologia di navigazione.<br />
          Per quanto riguarda Strumenti di Tracciamento di terza parte, gli Utenti possono gestire le preferenze e revocare il consenso visitando il relativo link di opt out (qualora disponibile), utilizzando gli strumenti descritti nella privacy policy della terza parte o contattandola direttamente.<br />
          Specifica l’ID del suo consenso e la data di quando ci hai contattati per quanto riguarda il suo consenso.
        </p>
        <h3>I DIRITTI DEGLI UTENTI</h3>
        <p>
          Gli Utenti possono esercitare i diritti di cui agli artt. 16-21 Reg. Europeo 2016/679 (Diritto di rettifica, diritto all’oblio, diritto di limitazione di trattamento, diritto alla portabilità dei dati, diritto di opposizione), che sono indicati di seguito.
        </p>
        <p>
          Al fine di ricevere ogni informazione relativa alla presente informativa e esercitare i diritti indicati di seguito, l’Utente può contattare:
        </p>
        <ul>
          <li>il Responsabile della protezione dei dati (DPO) Avv. Roberto Vasapolli, nominato da Vicenzi S.p.A. in qualità di titolare del trattamento, all’indirizzo <a href='mailto:dpo@vicenzi.it'>dpo@vicenzi.it</a></li>
          <li>
            Il titolare del trattamento Vicenzi S.p.A., inviando una raccomandata presso la sede legale, sita in Via Forte Garofolo 1, 37057 San Giovanni Lupatoto, Verona, ovvero inviando una pec all’indirizzo <a href='mailto:vicenzibiscotti@mail-cert.it'>vicenzibiscotti@mail-cert.it</a>.
          </li>
        </ul>
        <p>
          Si precisa, tuttavia, che, con riguardo ai cookie o altri Strumenti di Tracciamento di terze parti, il titolare del trattamento non è Vicenzi S.p.A., ma la relativa terza parte. Si raccomanda di consultare l’informativa resa da tale terza parte.<br/>
          Gli Utenti potranno infine promuovere reclamo all’Autorità Garante ove necessario, ovvero rivolgersi alla stessa per richiedere informazioni in merito all’esercizio dei propri diritti derivanti dal Regolamento Europeo 2016/679.
        </p>
        <p>
          Nello specifico:
        </p>
        <ul>
          <li>il diritto di accesso consente di ottenere la conferma o meno di dati personali che lo riguardano e di ottenere l’accesso a tali dati e a specifiche informazioni (ad es. finalità del trattamento, categorie dei dati in questione, i destinatari a cui i dati saranno comunicati);</li>  
          <li>il diritto di rettifica: consente di ottenere la rettifica dei dati inesatti che lo riguardano senza ingiustificato ritardo. In tal caso sorge l’obbligo da parte del titolare del trattamento di comunicare la rettifica a tutti i destinatari cui sono stati trasmessi i dati, salvo che ciò non implichi uno sforzo sproporzionato;</li>  
          <li>il diritto alla cancellazione: consente di ottenere la cancellazione dei dati che lo riguardano senza ingiustificato ritardo e il titolare del trattamento ha l’obbligo di cancellarli senza ingiustificato ritardo se sussistono determinati motivi (ad es. i dati personali non sono più necessari rispetto alle finalità per cui erano stati raccolti; se l’interessato revoca il consenso; se devono essere cancellati per un obbligo legale). In tal caso sorge l’obbligo da parte del titolare del trattamento di comunicare la cancellazione a tutti i destinatari cui sono stati trasmessi i dati, salvo che ciò non implichi uno sforzo sproporzionato;</li>  
          <li>
            il diritto di limitazione del trattamento: consente di disporre al titolare del trattamento una restrizione al trattamento dei dati, ad esempio alla sola conservazione con esclusione di qualsiasi altro utilizzo, in determinate ipotesi (ad es. se il trattamento è illecito e l’interessato si oppone alla cancellazione dei dati; se l’interessato contesta l’esattezza, nei limiti del periodo di verifica dell’esattezza…). In tal caso sorge l’obbligo da parte del titolare del trattamento di comunicare la limitazione del trattamento a tutti i destinatari cui sono stati trasmessi i dati, salvo che ciò non implichi uno sforzo sproporzionato;
          </li>
          <li>
            il diritto alla portabilità dei dati, consente di ottenere la restituzione dei dati personali forniti e trasmetterli ad altri o per chiedere la trasmissione da un titolare all’altro, se tecnicamente fattibile;
          </li>
          <li>
            il diritto di opposizione, consente di opporsi in qualsiasi momento al trattamento per scopi di interesse pubblico o per legittimo interesse; per finalità di marketing; per fini di ricerca scientifica, storica o statistica.
          </li>
        </ul>
        <p> 
          Gli interessati possono fare reclamo all’Autorità Garante (www.garanteprivacy.it) se necessario, o semplicemente contattarla per avere informazioni inerenti all’esercizio dei propri diritti riconosciuti dal Reg. UE 2016/679.
        </p>
        <h3>TIPOLOGIA DI COOKIE E STRUMENTI DI TRACCIAMENTO</h3>
        <p>Questo sito web utilizza i cookie.</p>
        <p>
          Questo sito web utilizza cookie o altri strumenti di tracciamento. L’utilizzo dei cookie necessari (detti anche tecnici) è obbligatorio per consentire la navigazione sul sito web, l’utilizzo di cookie non necessari (detti anche non tecnici) o altri strumenti di tracciamento viene effettuato solo se presti il tuo consenso, per personalizzare contenuti ed annunci, per fornire funzionalità dei social media, per analizzare il nostro traffico. Per sapere di più premi qui e consulta l’informativa completa.
        </p>
        <p>
          <b>Premendo il pulsante “accetta tutti i cookie”</b>, acconsenti all’utilizzo di tutti i cookie necessari e non necessari in funzione sul Sito.
        </p>
        <p>
          <b>Premendo il pulsante “acconsenti ai cookie selezionati”</b>, acconsenti all’utilizzo dei cookie che hai selezionato per macro area utilizzando l’apposita funzione indicata qui sotto.
        </p>
        <p>
          <b>“Premendo il pulsante “usa solo i cookie necessari” oppure chiudendo il banner premendo la X</b>, proseguirai la navigazione sul Sito e verranno utilizzati unicamente i cookie necessari a tale scopo”
        </p>
       
        
        

        <div id="cookie-content">
        </div>
        <h3>Altre informazioni</h3>
        <h4>Google Analytics con IP anonimizzato (Google Inc.)</h4>
        <p>
          Google Analytics è un servizio di analisi web fornito da Google Inc. (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questa Applicazione, compilare report e condividerli con gli altri servizi sviluppati da Google.
        </p>
        <p>
          Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.
        </p>
        <p>
          Vicenzi S.p.A. adotta questa integrazione di Google Analytics che rende anonimo l’indirizzo IP degli utenti. L’anonimizzazione funziona abbreviando entro i confini degli stati membri dell’Unione Europea o in altri Paesi aderenti all’accordo sullo Spazio Economico Europeo l’indirizzo IP degli Utenti. Solo in casi eccezionali, l’indirizzo IP sarà inviato ai server di Google ed abbreviato all’interno degli Stati Uniti.
        </p>
        <p>
          Dati Personali raccolti: Cookie e Dati di Utilizzo. Luogo del trattamento: USA
        </p>
        <p>
          L’utente può` ricevere informazioni sulle attività` di trattamento dei suoi dati personali poste in essere da Google Analytics al seguente indirizzo: <a href='https://support.google.com/analytics/answer/6004245' target='_blank'>https://support.google.com/analytics/answer/6004245</a>;<br /> Ulteriori informazioni sulla funzionalità di anonimizzazione IP di Google Analytics: <a href='https://support.google.com/analytics/answer/2763052?hl=it' target='_blank'>https://support.google.com/analytics/answer/2763052?hl=it</a>
        </p>
        <h3>Disabilitazione (“opt-out”) per i cookie:</h3>
        <p>
          Le norme sulla protezione dei dati personali prevedono che l’utente possa autorizzare l’utilizzo di cookie (“opt-in”) proseguendo nella navigazione dopo aver letto la finestra con l’avviso relativo all’utilizzo dei cookie. L’utente può` inoltre disabilitare cookie già` somministrati (“opt- out”). L’opt-out e` previsto per i cd. “cookie tecnici” (art. 122 del Codice), nonché´ per i cookie che non rientrino tra i “cookie tecnici” (“targeting cookie” e “cookie analytics”) precedentemente accettati (“opt in”) dall’utente (se presenti).<br/>
          In forza di tale distinzione, l’utente potrà` procedere alla disabilitazione e/o cancellazione dei cookie (“opt-out”) mediante le relative impostazioni del proprio browser e alla disabilitazione e/o cancellazione dei singoli cookie non “tecnici” accedendo ai seguenti siti:
        </p>
        <ol>
          <li>
            <a href='https://tools.google.com/dlpage/gaoptout?hl=itper' target='_blank'>https://tools.google.com/dlpage/gaoptout?hl=itper</a> la disattivazione di Google Analytics
          </li>
          <li>
            <a href='https://youronlinechoices.eu/' targe='_blank'>youronlinechoices.eu</a>, sito web gestito da European Interactive Digital Advertising Alliance (EDAA), per la cancellazione degli altri cookies nel caso degli utenti con sede nell’Unione Europea,</li>
          <li>
            <a href='http://www.aboutads.info/choises/' target='_blank'>http://www.aboutads.info/choises/</a>, per la cancellazione degli altri cookies nel caso degli utenti residenti negli Stati Uniti d’America. Tali siti non sono gestiti da Vicenzi S.p.A., che non si assume dunque alcuna responsabilità` in relazione ai rispettivi contenuti.
          </li>
        </ol>
        <h4>Come abilitare o disabilitare i cookies sui propri browser:</h4>
        <p>
          L’utente può` bloccare l’accettazione di cookies da parte del browser di navigazione. Tuttavia, questa operazione potrebbe rendere meno efficiente o impedire l’accesso ad alcune funzioni o pagine del Sito. Di seguito riportiamo le modalità` offerte dai principali browser per bloccare l’accettazione di cookies di navigazione:</p>
        <ul>
          <li>
            Microsoft Edge: <a href='https://support.microsoft.com/it-it/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy' target='_blank'>https://support.microsoft.com/it-it/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy</a>
          </li>
          <li>Internet Explorer: <a href='http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11' target='_blank'>http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11</a></li>
          <li>Firefox: <a href='https://support.mozilla.org/it/kb/Gestione%20dei%20cookie' target='_blank'>https://support.mozilla.org/it/kb/Gestione%20dei%20cookie</a></li>
          <li>Chrome: <a href='https://support.google.com/chrome/answer/95647?hl=it' target='_blank'>https://support.google.com/chrome/answer/95647?hl=it</a></li>
          <li>Safari: <a href='http://support.apple.com/kb/HT1677?viewlocale=it_IT' target='_blank'>http://support.apple.com/kb/HT1677?viewlocale=it_IT</a></li>
          </ul>
          <h3>Collegamenti ai siti di terze parti.</h3>
        <p>Vicenzi S.p.A. non è responsabile per le politiche applicate dai siti collegati o per le informazioni o i contenuti ivi presenti. Spesso i collegamenti ad altri siti sono presenti esclusivamente come “punti di informazione e/o approfondimento relativamente a determinati argomenti che potrebbero risultare utili agli utenti. Da ricordare che quando ci si collega dal nostro sito ad un altro sito, la nostra politica privacy non è più efficace. Navigare ed interagire con altri siti, compresi i siti collegati al nostro, sono soggetti ognuno alle proprie regole e policies. Si invita a leggere attentamente le regole e policies ivi presenti prima di procedere. Il rischio nella navigazione di questi siti di proprietà di terze parti è interamente a vostro carico. Il collegamento a siti di terze parti, o a qualsiasi prodotto, processo pubblicazione, servizio o offerte di terze parti non costituiscono o comportano approvazione o raccomandazione dei prodotti o servizi stessi da parte di Vicenzi S.p.A..</p>
      </div>
      <Footer/>
    </div>
  );
}

export default Cookie;