import './Mouth.scss';

function Mouth() {
  return (
    <div id="mouth" className="mouth">

      <svg className="top" width="246" height="123" viewBox="0 0 246 123" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path id="top_2" d="M3.07667 72.668C3.4013 71.5138 4.07989 70.4906 5.01683 69.7426C5.95377 68.9945 7.10193 68.5593 8.2993 68.4983L28.616 68.2515L37.2221 68.1488C94.134 67.4685 151.046 66.815 207.959 66.1883L216.566 66.0904L236.884 65.8566C238.073 65.8833 239.221 66.2903 240.161 67.0176C241.102 67.745 241.784 68.7545 242.108 69.898C242.343 70.5952 242.466 71.3254 242.471 72.0611L242.476 89.3741C239.608 91.1694 236.287 92.1092 232.904 92.0831C225.18 91.9578 218.197 86.6439 213.197 78.3612C208.367 90.5101 199.931 98.3484 190.269 98.249C182.867 98.1758 176.137 93.5257 171.183 86.0974C166.686 99.8889 157.506 109.197 146.869 109.207C136.354 109.215 127.21 100.207 122.602 86.9113C118.01 100.316 108.877 109.529 98.3621 109.761C87.7202 109.996 78.5283 100.901 74.0181 87.21C69.0688 94.753 62.3446 99.5593 54.943 99.8066C45.2773 100.131 36.8308 92.4908 31.9951 80.4497C26.9996 88.8532 20.0212 94.3341 12.2941 94.6441C8.93022 94.7562 5.60569 93.8939 2.72037 92.161C2.718 86.387 2.71624 80.613 2.7152 74.8389C2.71971 74.1003 2.84067 73.3671 3.07356 72.6662" fill="#EFEFEF"/>
          <g id="glasses">
            <path id="Tracciato 18" fillRule="evenodd" clipRule="evenodd" d="M159.628 21.7927C172.684 26.9822 232.253 50.9566 236.115 59.953C236.839 61.5637 237.126 63.336 236.95 65.0928C236.527 68.9825 232.244 76.0494 226.788 75.6686C224.158 75.4856 223.485 73.525 222.812 71.7314C219.082 61.8304 166.149 41.3381 153.443 36.4264L159.628 21.7927Z" fill="black"/>
            <path id="Tracciato 19" d="M161.574 25.9624C161.289 28.5817 160.456 36.2985 158.34 38.0964C156.753 39.4434 154.626 39.3896 152.568 39.5949C145.86 40.27 144.548 83.6619 135.077 90.874C131.883 93.3064 125.193 92.7252 121.328 92.4555C109.522 91.6303 83.0937 88.0629 73.7393 82.479C63.8663 76.5854 68.2536 44.7513 67.0682 35.1341C66.7806 32.7915 64.8466 31.7248 62.2229 31.5381C59.2914 31.3337 56.3067 32.3471 55.6018 35.0062C53.5706 42.6715 52.6405 75.194 43.6277 77.762C40.2712 78.5625 36.8027 78.7849 33.3715 78.4196C26.992 77.924 20.6862 76.7282 14.5682 74.8537C12.1541 74.1261 9.56282 73.277 8.23047 71.2796C5.61092 67.3484 5.78816 52.9765 5.6583 48.0214C5.62222 46.7679 5.58563 37.6492 4.12806 37.2619C3.12125 36.9954 2.52735 37.0662 1.92669 36.2163C1.21251 35.2017 1.31569 29.6438 1.46901 28.2268C1.49387 27.7774 1.61247 27.3383 1.81727 26.9375C4.14048 23.086 26.2677 23.6288 30.7393 23.4933C37.9922 23.2728 45.2424 23.0184 52.4974 22.9004C55.6674 22.8485 59.0942 23.1116 62.228 22.8846C66.8132 22.5536 71.5545 21.6081 76.1528 21.1118C86.6289 19.9806 97.1564 19.1738 107.696 18.7911C120.722 18.3194 134.111 18.0234 147.105 18.9293C152.546 19.3087 162.259 19.5856 161.568 25.954" fill="black"/>
            <path id="Tracciato 20" d="M161.574 25.9624C161.289 28.5817 160.456 36.2985 158.34 38.0964C156.753 39.4434 154.626 39.3896 152.568 39.5949C145.86 40.27 144.548 83.6619 135.077 90.874C131.883 93.3064 125.193 92.7252 121.328 92.4555C109.522 91.6303 83.0937 88.0629 73.7393 82.479C63.8663 76.5854 68.2536 44.7513 67.0682 35.1341C66.7806 32.7915 64.8466 31.7248 62.2229 31.5381C59.2914 31.3337 56.3067 32.3471 55.6018 35.0062C53.5706 42.6715 52.6405 75.194 43.6277 77.762C40.2712 78.5625 36.8027 78.7849 33.3715 78.4196C26.992 77.924 20.6862 76.7282 14.5682 74.8537C12.1541 74.1261 9.56282 73.277 8.23047 71.2796C5.61092 67.3484 5.78816 52.9765 5.6583 48.0214C5.62222 46.7679 5.58563 37.6492 4.12806 37.2619C3.12125 36.9954 2.52735 37.0662 1.92669 36.2163C1.21251 35.2017 1.31569 29.6438 1.46901 28.2268C1.49387 27.7774 1.61247 27.3383 1.81727 26.9375C4.14048 23.086 26.2677 23.6288 30.7393 23.4933C37.9922 23.2728 45.2424 23.0184 52.4974 22.9004C55.6674 22.8485 59.0942 23.1116 62.228 22.8846C66.8132 22.5536 71.5545 21.6081 76.1528 21.1118C86.6289 19.9806 97.1564 19.1738 107.696 18.7911C120.722 18.3194 134.111 18.0234 147.105 18.9293C152.551 19.3172 162.264 19.5921 161.574 25.9624Z" stroke="black" strokeWidth="0.172" strokeMiterlimit="3.864"/>
            <path id="Tracciato 21" fillRule="evenodd" clipRule="evenodd" d="M133.384 24.0939C124.667 23.4862 77.3358 22.4595 73.4019 27.2964C72.6319 28.2425 71.9362 34.8317 71.8 36.0919C70.812 45.1876 69.0166 70.0527 75.4318 76.9942C77.1849 78.8303 79.465 80.0766 81.957 80.5609C94.2156 83.5553 106.691 85.5787 119.268 86.6124C121.599 86.7757 130.096 87.3963 131.564 85.3039C137.251 77.2048 140.408 56.8626 141.448 47.2876C141.693 45.0287 143.442 25.845 141.439 25.0676C140 24.5093 135.021 24.2072 133.386 24.0937" fill="black"/>
            <path id="Tracciato 22" d="M133.384 24.0939C124.667 23.4862 77.3358 22.4595 73.4019 27.2964C72.6319 28.2425 71.9362 34.8317 71.8 36.0919C70.812 45.1876 69.0166 70.0527 75.4318 76.9942C77.1849 78.8303 79.465 80.0766 81.957 80.5609C94.2156 83.5553 106.691 85.5787 119.268 86.6124C121.599 86.7757 130.096 87.3963 131.564 85.3039C137.251 77.2048 140.408 56.8626 141.448 47.2876C141.693 45.0287 143.442 25.845 141.439 25.0676C139.994 24.5099 135.015 24.2078 133.384 24.0939Z" stroke="black" strokeWidth="0.172" strokeMiterlimit="3.864"/>
            <path id="Tracciato 23" fillRule="evenodd" clipRule="evenodd" d="M133.384 24.0939C124.667 23.4862 77.3358 22.4595 73.4019 27.2964C72.6319 28.2425 71.9362 34.8317 71.8 36.0919C70.812 45.1876 69.0166 70.0527 75.4318 76.9942C77.1849 78.8303 79.465 80.0766 81.957 80.5609C94.2156 83.5553 106.691 85.5787 119.268 86.6124C121.599 86.7757 130.096 87.3963 131.564 85.3039C137.251 77.2048 140.408 56.8626 141.448 47.2876C141.693 45.0287 143.442 25.845 141.439 25.0676C140 24.5093 135.021 24.2072 133.386 24.0937" fill="#0465A7"/>
            <path id="Tracciato 24" d="M133.384 24.0939C124.667 23.4862 77.3358 22.4595 73.4019 27.2964C72.6319 28.2425 71.9362 34.8317 71.8 36.0919C70.812 45.1876 69.0166 70.0527 75.4318 76.9942C77.1849 78.8303 79.465 80.0766 81.957 80.5609C94.2156 83.5553 106.691 85.5787 119.268 86.6124C121.599 86.7757 130.096 87.3963 131.564 85.3039C137.251 77.2048 140.408 56.8626 141.448 47.2876C141.693 45.0287 143.442 25.845 141.439 25.0676C139.994 24.5099 135.015 24.2078 133.384 24.0939Z" stroke="black" strokeWidth="0.172" strokeMiterlimit="3.864"/>
            <path id="Tracciato 25" d="M116.308 24.1642C110.356 34.7498 103.272 45.0131 96.682 55.3232L81.3149 79.3876L79.0563 78.8865L97.2923 24.0437C103.635 23.8803 109.973 23.9054 116.308 24.1642Z" fill="#2C8BCC"/>
            <path id="Tracciato 26" d="M141.252 25.1745C136.22 33.5493 119.496 49.2908 111.919 56.8647L87.7276 81.1127L85.3556 80.5776C98.0583 61.8109 110.977 43.1564 124.111 24.6141C129.83 24.6947 135.535 24.9901 141.252 25.1755" fill="#2C8BCC"/>
            <path id="Tracciato 27" d="M95.9085 83.2033C102.199 79.4775 108.115 74.849 113.993 70.6601C123.19 64.1097 132.329 57.3653 140.784 50.1142C139.7 58.8321 136.503 77.9954 131.271 85.1769C129.734 87.2839 120.967 86.6253 118.579 86.4602C110.968 85.7899 103.4 84.7029 95.9086 83.2042" fill="#124687"/>
            <path id="Tracciato 28" d="M93.3265 24.3001C87.2969 41.9577 82.4731 60.0441 77.3058 77.8974C77.176 77.7315 76.3038 76.6989 76.2812 76.6109C75.9189 75.1334 76.6567 54.5995 76.7149 51.2404L77.1447 26.0021C82.5192 25.2994 87.9145 24.7142 93.3265 24.3001" fill="#2C8BCC"/>
            <path id="Tracciato 29" fillRule="evenodd" clipRule="evenodd" d="M45.1212 26.9639C46.6416 27.0697 50.0715 27.2781 51.1252 28.3523C52.0735 29.3192 50.7754 44.5184 50.5543 46.5866C49.8552 53.0263 48.55 74.8594 37.692 74.1028C33.0212 73.7772 11.086 71.5628 9.86721 67.9315C7.38672 60.5428 8.05835 40.9174 8.93544 32.8256C9.12485 31.2871 9.43143 29.7653 9.85245 28.2734C11.0795 27.0452 41.0259 26.6761 45.1179 26.9612" fill="black"/>
            <path id="Tracciato 30" d="M45.1212 26.9638C46.6416 27.0697 50.0715 27.2781 51.1251 28.3522C52.0735 29.3191 50.7754 44.5184 50.5543 46.5866C49.8552 53.0263 48.55 74.8594 37.692 74.1028C33.0212 73.7772 11.086 71.5627 9.86719 67.9315C7.38671 60.5428 8.05833 40.9174 8.93542 32.8256C9.12483 31.2871 9.43141 29.7653 9.85243 28.2734C11.0799 27.0481 41.0293 26.6797 45.1212 26.9638Z" stroke="black" strokeWidth="0.172" strokeMiterlimit="3.864"/>
            <path id="Tracciato 31" fillRule="evenodd" clipRule="evenodd" d="M45.1212 26.9639C46.6416 27.0697 50.0715 27.2781 51.1252 28.3523C52.0735 29.3192 50.7754 44.5184 50.5543 46.5866C49.8552 53.0263 48.55 74.8594 37.692 74.1028C33.0212 73.7772 11.086 71.5628 9.86721 67.9315C7.38672 60.5428 8.05835 40.9174 8.93544 32.8256C9.12485 31.2871 9.43143 29.7653 9.85245 28.2734C11.0795 27.0452 41.0259 26.6761 45.1179 26.9612" fill="#0465A7"/>
            <path id="Tracciato 32" d="M45.1212 26.9638C46.6416 27.0697 50.0715 27.2781 51.1251 28.3522C52.0735 29.3191 50.7754 44.5184 50.5543 46.5866C49.8552 53.0263 48.55 74.8594 37.692 74.1028C33.0212 73.7772 11.086 71.5627 9.86719 67.9315C7.38671 60.5428 8.05833 40.9174 8.93542 32.8256C9.12483 31.2871 9.43141 29.7653 9.85243 28.2734C11.0799 27.0481 41.0293 26.6797 45.1212 26.9638Z" stroke="black" strokeWidth="0.172" strokeMiterlimit="3.864"/>
            <path id="Tracciato 33" d="M22.0566 27.7223C26.7258 43.0254 32.9385 58.138 38.2906 73.2831L40.0006 73.3814C37.8598 57.9605 35.653 42.5473 33.3804 27.1415C29.6044 27.2816 25.8256 27.4623 22.0566 27.7223" fill="#2C8BCC"/>
            <path id="Tracciato 34" d="M9.92868 28.3456C12.1003 35.4662 18.9838 46.3703 22.7465 53.2671L33.6446 73.2385L35.3063 73.3399C29.7186 58.1783 23.9533 43.0688 18.0102 28.0116C15.3093 28.0396 12.6248 28.2186 9.92879 28.3466" fill="#2C8BCC"/>
            <path id="Tracciato 35" d="M28.2276 73.0636C21.6685 65.0513 13.7834 53.1439 8.36266 44.3355C8.21469 50.0915 8.13606 62.6822 10.0052 67.8963C11.0341 70.7637 24.8389 72.6233 28.2281 73.0676" fill="#124687"/>
            <path id="Tracciato 36" d="M36.0331 27.142C38.4407 42.3043 39.8062 57.697 41.4383 72.943C41.5627 72.8288 42.3576 72.1486 42.3827 72.0745C42.9154 70.7328 44.8607 52.7681 45.2476 49.7564L48.1086 27.4771C44.087 27.2939 40.06 27.1717 36.0331 27.142Z" fill="#2C8BCC"/>
            <path id="Tracciato 37" d="M234.552 66.3119C234.447 67.2961 234.478 69.3187 232.856 69.2068C231.491 69.1102 232.505 66.9113 232.582 66.2028C232.761 64.5641 232.495 62.9075 231.813 61.4069C227.862 52.6576 183.675 36.2052 173.037 30.8235C172.748 30.677 172.561 30.5063 172.595 30.1998C172.635 29.992 172.744 29.8039 172.905 29.6663C173.065 29.5287 173.268 29.4498 173.479 29.4425C173.648 29.4459 173.816 29.4737 173.977 29.525C183.948 32.8639 230.184 53.2886 233.681 60.9196C234.453 62.6057 234.754 64.4698 234.55 66.3132" fill="#B5B4B4"/>
            <path id="Tracciato 38" d="M151.965 20.9061C156.051 21.1909 158.648 21.4419 158.203 25.5262C158.047 26.9595 157.543 31.8611 156.491 32.5009C156.28 32.3116 155.983 32.0824 156.014 31.8166C155.973 29.981 155.602 28.1679 154.918 26.4638C154.307 24.8756 153.281 22.8449 151.421 22.0679C151.031 21.9042 150.489 22.0247 150.158 21.7832C149.976 21.6271 149.806 21.4569 149.651 21.2741C150.306 20.6153 151.067 20.8461 151.959 20.9078" fill="#B5B4B4"/>
            <path id="Tracciato 39" d="M225.919 74.0442C225.863 74.5533 225.415 74.6293 224.92 74.5948C223.58 74.5005 224.403 70.4916 223.02 68.372C220.242 64.116 202.66 56.0061 197.449 53.6168C188.898 49.7086 180.207 46.0209 171.487 42.3958C167.109 40.5766 162.373 38.9514 158.15 36.909C157.933 36.805 157.539 36.5482 157.567 36.2684C157.6 35.963 157.724 35.4838 158.239 35.5192C158.41 35.5259 158.579 35.5569 158.741 35.6113C169.588 39.2771 219.702 60.4691 224.686 67.8735C226.247 70.196 226.207 71.3944 225.919 74.0401" fill="#504F4F"/>
          </g>
        </g>
      </svg>
      
      <div id="mouth_inner" className="inner"></div>
      
      <svg className="bottom" width="254" height="163" viewBox="0 0 254 163" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.73576 24.3608C2.70825 30.1361 2.76418 26.3461 2.73576 32.1188C2.73576 32.1188 2.74145 36.2287 2.73576 37.0757C2.58874 75.5498 15.9995 106.036 38.471 124.561C40.598 126.337 42.8026 128.016 45.0844 129.596C65.5907 143.775 91.9978 149.495 121.576 149.456C151.158 149.274 178.153 142.906 198.739 128.486C201.035 126.882 207.649 122.13 209.789 120.334C232.367 101.528 242.233 74.2148 242.469 35.6523C242.474 34.8093 242.524 30.8915 242.524 30.8915C242.524 30.8915 242.496 28.5452 242.524 22.7746L2.73576 22.7746C2.73576 22.7746 2.74353 23.6226 2.73576 24.3608Z" fill="#E3501E"/>
        <path d="M123.404 150.248C93.8534 151.507 70.6108 145.947 49.5372 132.627C47.1907 131.142 44.9182 129.556 42.7196 127.869C46.8691 122.019 52.0053 118.036 57.6635 116.829C66.4521 114.95 74.5515 120.152 79.9254 129.424C84.6118 118.473 92.3039 110.844 101.25 109.914C111.864 108.8 121.451 117.37 126.624 130.502C130.811 116.945 139.728 107.519 150.385 107.657C159.36 107.763 167.575 114.669 173.044 125.17C177.716 115.427 185.392 109.49 194.271 110.551C199.985 111.233 205.378 114.738 209.937 120.201C207.868 122.084 205.733 123.89 203.507 125.586C183.532 140.845 165.424 148.846 135.873 150.248" fill="#EFEFEF"/>
      </svg>
    
    </div>
  );
}

export default Mouth;
