import { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import './Privacy.scss';
import Header from '../../components/Header/Header';

function Privacy() {

 

  return (
    <div className="cookie_policy">
      <Header logo="/img/logo.png" />
      <div className='container'>
        <div className='row'><div className="col-md-12 general_content">
          <h1 className="my-5 bolder">Privacy Policy</h1>

          <h3 className="wp-block-heading">Il Trattamento dei dati personali</h3>



          <p>Vicenzi S.p.A. (in seguito “Vicenzi”) in qualità di Titolare del Trattamento dei Dati Personali, Vi informa che l’accesso al sito web e/o l’iscrizione alle varie sezioni di esso e/o eventuali richieste di informazioni o di servizi richiedono il conferimento di dati personali che saranno oggetto di trattamento nel pieno rispetto del Regolamento Europeo 2016/679 (di seguito “GDPR”). Attraverso tale informativa gli utenti potranno conoscere, in maniera preventiva, le modalità di trattamento necessarie all’utilizzo di alcune aree del sito web&nbsp;<a href="https://www.mrday.it/">www.mrday.it</a>&nbsp;(in seguito anche “Sito”) e/o all’iscrizione alla newsletter.</p>



          <p>Ai sensi della predetta Legge tale trattamento sarà improntato ai principi di correttezza, liceità e trasparenza, tutelando la Vostra riservatezza ed i Vostri diritti.</p>



          <p>L’informativa ha efficacia esclusivamente per il presente sito e non si estende ad altri siti web eventualmente consultati dall’utente tramite collegamenti esterni.</p>



          <h3 className="wp-block-heading">Modifiche a questa Informativa</h3>



          <p>Vicenzi si riserva il diritto di apportare modifiche alla presente informativa in qualunque momento dandone indicazione agli utenti su questa pagina e/o avvisandoli via email, qualora ne. Vi preghiamo quindi di consultare spesso questa pagina, prendendo come riferimento la data di ultima modifica indicata in fondo. Nel caso di mancata accettazione delle modifiche apportate alla presente Informativa, l’utente è tenuto a cessare l’utilizzo di questa piattaforma richiedendo direttamente al Titolare del Trattamento di rimuovere i propri dati personali. Salvo quando diversamente specificato, la precedente Informativa continuerà ad applicarsi ai dati personali sino a quel momento raccolti.</p>



          <h3 className="wp-block-heading">Il Titolare ed i Responsabili del trattamento</h3>



          <p>Titolare del trattamento dei dati è&nbsp;<strong>VICENZI S.p.A.</strong>, con sede in Via Forte Garofolo 1, 37057 San Giovanni Lupatoto, Verona.</p>



          <p><strong>VICENZI S.p.A.</strong>,  ai sensi dell’art. 37 del GDPR, ha provveduto a designare il Responsabile della Protezione dei Dati (o Data Protection Officer – DPO), che può essere contattato al seguente indirizzo mail:&nbsp;<a href="mailto:%64po@%76i%63%65n%7Ai.it">dpo@vicenzi.it</a></p>



          <h3 className="wp-block-heading">Le finalità del trattamento e la base giuridica</h3>



          <p>Secondo le esigenze di volta in volta manifestate dall’utente che accede alle varie sezioni del Sito (e salvo norme e informative particolari per singole operazioni che comportino il conferimento di specifici dati personali, pubblicate di volta in volta sul Sito), di seguito sono indicate le finalità del trattamento dei dati personali, cioè quelli conferiti direttamente dagli utenti mediante la compilazione dei moduli on-line oppure l’accesso diretto, tramite link, all’indirizzo di posta elettronica relativo al servizio richiesto, ovvero quelli acquisiti automaticamente mediante la navigazione (si veda la seguente sezione “Categorie di Dati Personali oggetto di trattamento”) (di seguito, “Dati Personali”):</p>



          <ol>
            <li>Rispondere alle richieste pervenuteci tramite richiesta diretta (form web “contatti” del Servizio Consumatori), detto trattamento può comprendere anche l’invio di e-mail “transazionali”, il cui scopo è connesso alla procedura di conferma di ricezione delle tue richieste. Base giuridica del trattamento: esecuzione di misure precontrattuali (art. 6 (1)(b));</li>



            <li>Offrire l’accesso alla sezione commenti del blog e l’ iscrizione al Club “Amiche di Matilde”,tale trattamento può comprendere anche l’invio di e-mail “transazionali”, il cui scopo è connesso alla procedura di registrazione ai servizi sopra elencati. Base giuridica del trattamento: esecuzione di misure precontrattuali (art. 6 (1)(b));</li>



            <li>Per adempiere alle richieste di offerte/informazioni riguardo la fornitura dei prodotti commercializzati da Vicenzi S.p.A., detto trattamento può comprendere anche l’invio di e-mail “transazionali”, il cui scopo è connesso alla procedura di conferma di ricezione delle tue richieste Base giuridica del trattamento: esecuzione di misure precontrattuali e/o contrattuali (art. 6 (1)(b));</li>



            <li>Previo consenso dell’utente e fino alla revoca dello stesso, effettuare attività di marketing quali, in via meramente esemplificativa ma non esaustiva: ricerche di mercato, invio di materiale informativo e promozionale, l’invio di campioni-prodotto omaggio, attività di marketing e pubblicità riguardanti i prodotti ed i servizi della scrivente società, utilizzando anche l’indirizzo di posta elettronica fornitoci (iscrizione alla newsletter). Base giuridica del trattamento: consenso (art. 6 (1) (a)).</li>



            <li>Necessità di accertare, esercitare o difendere un diritto in sede giudiziaria. Base giuridica del trattamento: legittimo interesse (art. 6 (1) (f)).</li>



            <li>Gestione ed esecuzione degli adempimenti previsti dalla legge (natura contabile, amministrativa, fiscale, ecc.). Base giuridica del trattamento: adempimento di un obbligo di legge (art. 6 (1) (c)).</li>
          </ol>



          <h3 className="wp-block-heading">Le modalità del trattamento</h3>



          <p>Per trattamento dei dati si intende qualsiasi operazione o complesso di operazioni, svolte con o senza ausilio di mezzi elettronici o comunque automatizzati, riguardanti la raccolta, la registrazione, l’organizzazione, la conservazione, l’elaborazione, la modificazione, l’estrazione, il raffronto, l’utilizzo, la comunicazione, la diffusione, l’interconnessione, il blocco, la cancellazione, la distruzione e la selezione dei dati stessi.</p>



          <p>I Dati Personali saranno trattati in forma prevalentemente automatizzata ma anche cartacea, con logiche strettamente correlate alle predette finalità, mediante i Data Base, le Piattaforme elettroniche gestite dal Titolare del trattamento o da terzi nominati Responsabili del trattamento (per l’elenco aggiornato l’utente può contattare il Titolare all’indirizzo indicato) e/o sistemi integrati di natura informatica e/o siti web di titolarità o in uso a Vicenzi S.p.A.. Il Titolare ha adottato idonee misure tecniche ed organizzative di sicurezza per proteggere gli utenti contro il rischio di perdita, abuso o alterazione dei Dati. In particolare utilizza i protocolli protetti di trasmissione dei Dati noti come HTTPS. Inoltre, conserva i dati degli utenti su Server siti nel territorio europeo. I Server sono soggetti a un sistema avanzato e quotidiano di back up e disaster recovery.</p>



          <h3 className="wp-block-heading">La durata del trattamento</h3>



          <p>I dati comunicati sono conservati per un arco di tempo non superiore a quello necessario al conseguimento delle finalità per le quali i dati personali sono trattati, o per un periodo più lungo, per fini consentiti dalla legge, e comunque cancellati senza ingiustificato ritardo.</p>



          <p>Per le finalità di richiesta informazioni: in relazione alla tipologia di richiesta per il tempo necessario ad adempiere all’obbligo normativo di conservazione e/o per eventuali esigenze legali. Per richieste generiche massimo 12 mesi. Per l’iscrizione alla newsletter massimo 24 mesi dall’ultima interazione (a titolo esemplificativo ma non esaustivo quando apri la mail contenente la nostra newsletter).</p>



          <h3 className="wp-block-heading">Il luogo del trattamento</h3>



          <p>I Dati Personali sono trattati principalmente presso la sede del Titolare e/o nei luoghi in cui si trovano i Responsabili. Per ulteriori informazioni, gli utenti possono contattare il Titolare con le modalità sopra riportate.</p>



          <h3 className="wp-block-heading">La natura e le modalità del conferimento dei Dati Personali degli utenti</h3>



          <p>Il conferimento di dati personali è facoltativo, tuttavia il mancato conferimento non consentirà l’evasione della richiesta e/o l’iscrizione alla newsletter e/o l’utilizzo dei servizi presenti sul sito web che necessitano di registrazione. Il conferimento dei Dati Personali può avvenire mediante la compilazione degli appositi campi nelle varie sezioni del Sito oppure con l’invio delle richieste via e-mail dove previsto.</p>



          <h3 className="wp-block-heading">Le categorie di Dati Personali oggetto di trattamento</h3>



          <p>Oltre ai Dati Personali conferiti direttamente dagli utenti (quali nome, cognome, indirizzo postale, di posta elettronica, ecc.), in fase di connessione al Sito, i sistemi informatici e le procedure software preposte al funzionamento del Sito stesso somministrano e/o acquisiscono automaticamente ed indirettamente alcune informazioni che potrebbero costituire dati personali, la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet (quali, in via meramente esemplificativa ma non esaustiva, i c.d. “cookie” (come meglio di seguito specificato), indirizzi “IP”, nomi di dominio dei computer utilizzati dagli utenti che si connettono al Sito, gli indirizzi in notazione “Url” delle risorse richieste, l’orario della richiesta al server, la navigazione sul Sito.</p>



          <h3 className="wp-block-heading">Categorie di soggetti che potranno venire a conoscenza dei Dati Personali degli utenti</h3>



          <p>I Dati Personali potranno essere portati a conoscenza di dipendenti o collaboratori del Titolare che, operando sotto la diretta autorità di quest’ultimo, trattano dati e sono nominati responsabili o autorizzati al trattamento ai sensi degli artt. 24-29 del Reg. Europeo 2016/679 o amministratori di sistema e che riceveranno al riguardo adeguate istruzioni operative dal Titolare; lo stesso avverrà – a cura dei Responsabili nominati dal Titolare – nei confronti dei dipendenti o collaboratori dei Responsabili stessi. I Responsabili, nominati dal Titolare, potrebbero essere società terze o altri soggetti (a titolo esemplificativo ma non esaustivo i soggetti a cui viene affidata attività di assistenza, comunicazione, promozioni e vendita di prodotti e/o servizi, fornitori di servizi IT, gestori e/o sviluppatori di siti web o applicazioni in essi contenute, gestori delle piattaforme elettroniche, partner) che svolgono attività in outsourcing per conto di Vicenzi S.p.A..</p>



          <h3 className="wp-block-heading">Ambito di comunicazione o diffusione dei Dati Personali degli utenti</h3>



          <p>I Dati Personali potranno essere portati a conoscenza di dipendenti o collaboratori del Titolare che, operando sotto la diretta autorità̀ di quest’ultimo, trattano dati e sono autorizzati del trattamento o amministratori di sistema e che riceveranno al riguardo adeguate istruzioni operative dal Titolare.</p>



          <p>I Dati Personali potranno essere altresì̀ portati a conoscenza dei Responsabili, nominati dal Titolare, come società̀ terze o altri soggetti (a titolo esemplificativo ma non esaustivo i soggetti a cui viene affidata attività̀ di assistenza, comunicazione, promozioni e vendita di prodotti e/o servizi ,organizzazione e gestione dei concorsi, fornitori di servizi IT, gestori e/o sviluppatori di siti web o applicazioni in essi contenute, gestori delle piattaforme elettroniche, aziende di trasporti, aziende di gestione del servizio clienti) che svolgono attività̀ in outsourcing per conto di Vicenzi S.p.A..</p>



          <h3 className="wp-block-heading">Trasferimento extra UE dei Dati Personali degli utenti</h3>



          <p>Il Titolare può trasferire i Suoi Dati Personali al di fuori dello Spazio Economico Europeo, nel qual caso il trasferimento è posto in essere dal Titolare del Trattamento previa stipula di Clausole Contrattuali Standard conformi ai modelli predisposti dalla Commissione europea con i fornitori dei server e/o dei servizi, ovvero verifica della registrazione del responsabile del trattamento esterno nell’ambito del sistema denominato “Privacy Shield”.</p>



          <h3 className="wp-block-heading">Minori</h3>



          <p>Il sito non contiene pressoché́ alcuna informazione destinata direttamente ai minori. I minori non devono conferire informazioni o dati personali. La partecipazione ai concorsi eventualmente presenti sul sito web è destinata esclusivamente ai maggiorenni.</p>



          <h3 className="wp-block-heading">Utilizzo dei Cookie.</h3>



          <p>E’ disponibile l’informativa completa sui cookies raggiungibile dal link “Cookie Policy” presente sul sito web.</p>



          <h3 className="wp-block-heading">Plugin Social Network</h3>



          <p>Il presente sito incorpora anche plugin e/o bottoni al fine di consentire la condivisione dei contenuti sui social network utilizzati dal visitatore. Quando si visita una pagina del nostro sito web che contiene un plugin, il browser dell’utente si collega direttamente ai server del social network da dove è caricato il plugin, il quale server può tracciare la propria visita al nostro sito web ed eventualmente anche associarla al proprio account del social, in particolare se si è connessi al momento della visita o se si abbia recentemente navigato in uno dei siti web contenenti plugin social. Se non si desidera che il social network registri i dati relativi alla propria visita sul nostro sito web, l’utente deve uscire dal proprio account social e si suggerisce anche di eliminare i cookie che il social network ha installato nel proprio browser (si vedano anche le istruzioni contenute nella nostra cookie policy).</p>



          <p>La raccolta e l’uso delle informazioni da parte di tali terzi sono regolati dalle rispettive informative privacy alle quali si prega di fare riferimento.</p>



          <h3 className="wp-block-heading">I diritti degli utenti</h3>



          <p>Gli utenti possono esercitare i diritti di cui agli artt. 16-21 Reg. Europeo 2016/679 (Diritto di rettifica, diritto all’oblio, diritto di limitazione di trattamento, diritto alla portabilità dei dati, diritto di opposizione). Gli utenti potranno infine promuovere reclamo all’Autorità Garante ove necessario, ovvero rivolgersi alla stessa per richiedere informazioni in merito all’esercizio dei propri diritti derivanti dal regolamento Europeo 2016/679. Nello specifico:</p>



          <ul>
            <li>Il diritto di accesso: per ottenere la conferma o meno di dati personali che lo riguardano e di ottenere l’accesso a tali dati e a specifiche informazioni (ad es. finalità del trattamento, categorie dei dati in questione, i destinatari a cui i dati saranno comunicati);</li>



            <li>Il diritto di rettifica: di ottenere la rettifica dei dati inesatti che lo riguardano senza ingiustificato ritardo. In tal caso sorge l’obbligo da parte del titolare del trattamento di comunicare la rettifica a tutti i destinatari cui sono stati trasmessi i dati, salvo che ciò non implichi uno sforzo sproporzionato;</li>



            <li>Il diritto alla cancellazione: per ottenere la cancellazione dei dati che lo riguardano senza ingiustificato ritardo e il titolare del trattamento ha l’obbligo di cancellarli senza ingiustificato ritardo se sussistono determinati motivi (ad es. i dati personali non sono più necessari rispetto alle finalità per cui erano stati raccolti; se l’interessato revoca il consenso; se devono essere cancellati per un obbligo legale). In tal caso sorge l’obbligo da parte del titolare del trattamento di comunicare la cancellazione a tutti i destinatari cui sono stati trasmessi i dati, salvo che ciò non implichi uno sforzo sproporzionato;</li>



            <li>Il diritto di limitazione del trattamento: si può disporre al titolare del trattamento una restrizione al trattamento dei dati, ad esempio alla sola conservazione con esclusione di qualsiasi altro utilizzo, in determinate ipotesi (ad es. se il trattamento è illecito e l’interessato si oppone alla cancellazione dei dati; se l’interessato contesta l’esattezza, nei limiti del periodo di verifica dell’esattezza…). In tal caso sorge l’obbligo da parte del titolare del trattamento di comunicare la limitazione del trattamento a tutti i destinatari cui sono stati trasmessi i dati, salvo che ciò non implichi uno sforzo sproporzionato;</li>



            <li>Il diritto alla portabilità dei dati: per ottenere la restituzione dei dati personali forniti e trasmetterli ad altri o per chiedere la trasmissione da un titolare all’altro, se tecnicamente fattibile;</li>



            <li>Il diritto di opposizione: per opporsi in qualsiasi momento al trattamento per scopi di interesse pubblico o per legittimo interesse; per finalità di marketing; per fini di ricerca scientifica, storica o statistica.</li>
          </ul>



          <p>Gli interessati possono fare reclamo all’Autorità Garante (<a href="http://www.garanteprivacy.it/">www.garanteprivacy.it</a>) se necessario, o semplicemente contattarla per avere informazioni inerenti all’esercizio dei propri diritti riconosciuti dal Reg. UE 2016/679.</p>



          <p><strong>(Rev. 9/2023)</strong></p>
        </div></div>
        </div>
      <Footer/>
    </div>
  );
}

export default Privacy;