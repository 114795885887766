import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './pages/App/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import Cookie from './pages/Cookie/Cookie';
import Privacy from './pages/Privacy/Privacy';
import Product from './pages/Product/Product';
import Players from './pages/Players/Players';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<App />} />
        <Route exact path='/cookie-policy' element={<Cookie />} />
        <Route exact path='/privacy-policy' element={<Privacy />}/>
        <Route exact path='/muffin-super-pepite-cioccolato-fondente' element={<Product name='muffin-super-pepite-cioccolato-fondente' />}/> 
        <Route exact path='/muffin-proteico-caramello-salato' element={<Product name='muffin-proteico-caramello-salato' /> } />
        <Route exact path='/muffin-super-al-cacao' element={<Product name='muffin-super-al-cacao' />} />
        <Route exact path='/muffin-senza-glutine-cioccolato-fondente' element={<Product name='muffin-senza-glutine-cioccolato-fondente' /> } />
        <Route exact path='/ciambella-vaniglia' element={<Product name='ciambella-vaniglia' /> }/>
        <Route exact path='/ciambella-crema-al-cioccolato' element={<Product name='ciambella-crema-al-cioccolato' />}/>
        <Route exact path='/plumcake-goccia-di-miele' element={<Product name='plumcake-goccia-di-miele' /> }/>
        <Route exact path='/brownies-al-cioccolato' element={<Product name='brownies-al-cioccolato' /> }/>
        <Route exact path='/players' element={<Players /> }/>
        <Route path="*" element={<App />} />
      </Routes>
      
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
