import React, { useEffect, useRef } from "react";
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import './Players.scss';
const creators=[
    {
        img: "venom.png",
        alt: "MrDay Player: Venom"
    },
    {
        img: "lavica.png",
        alt: "MrDay Player: Lavica"
    },
    {
        img: "tearless.png",
        alt: "MrDay Player: Tearless Raptor"
    },
    {
        img: "futuniverse.png",
        alt: "MrDay Player: Futuniverse"
    },
    {
        img: "kinggm95.png",
        alt: "MrDay Player: Kinggm95"
    },
    {
        img: "kodomo.png",
        alt: "MrDay Player: Kodomolive"
    },
    {
        img: "menzo.png",
        alt: "MrDay Player: Menzo"
    },
    {
        img: "hemerald.png",
        alt: "MrDay Player: Hemerald"
    },  
];

const gallery_1 = [
    {
        img: "awed.png",
        alt: "MrDay Player: Awed"
    },
    {
        img: "giulia.png",
        alt: "MrDay Player: Giulia"
    },
    {
        img: "zerbiian.png",
        alt: "MrDay Player: Zerbian"
    }
];
const gallery_2 = [
    {
        img: "bazy-b.png",
        alt: "MrDay Player: Bazy"
    },
    {
        img: "playerinside-o.png",
        alt: "MrDay: Player inside"
    },
    {
        img: "seleyes-b.png",
        alt: "MrDay Player: Seleyes"
    }
];

function Players() {
    const scrolltarget = useRef(null);
    return (
        <div className="players-page" >
            <Header logo="/img/logo.png" />
            <section id='intro_players'>
                <div id="title_players" className='container'>
                    
                        <img src='/img/players/sfondi/muffin_players.png' id='muffin_players_head'/>
                        <img src='/img/players/elementi/bocca-muffin-24.png' id='muffin_bocca'/>
                    <div id='title_player_text'>
                        <h1>PLAYERS</h1>
                        <h3>Scopriamo chi ha giocato con noi,<br/> tra una pausa e l’altra!</h3>
                        <img src='/img/players/elementi/button.svg' onClick={() =>
                            scrolltarget.current.scrollIntoView({
                                behavior: "smooth",
                                block: "start"
                            })} id='button_players'></img>
                    </div>
                </div>
            </section>
            <section id='upgrade-your-skills-wrap' >
                <div id='upgrade-your-skills' ref={scrolltarget} className='container'>
                    <div id='upgrade-your-skills-row'>
                        <div id='upgrade-your-skills-left' style={{ backgroundImage: 'url(/img/players/sfondi/tablet.png)' }}>
                            <video controls>
                                <source src="/video/mrday-creators.mp4" type="video/mp4" />
                            </video>
                        </div>
                        <div id='upgrade-your-skills-right'>
                            <div className='upgrade-your-skills-box'>
                                <img src='/img/players/elementi/upgrade-your-skills.png'/>
                                <p>
                                    Scendere in campo con il nostro creator team del cuore: è quello che abbiamo fatto nel 2023, dove abbiamo potenziato le nostre gaming skills con i muffin e le ciambelle migliori che ci siano!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section id='mr-day-creators' style={{ backgroundImage: 'url(/img/players/sfondi/sfondo-stelle.png)' }}>
                <div id='onda-creators' style={{ backgroundImage: 'url(/img/players/sfondi/forma-1.png)' }}></div>
                <div className="container">
                    <div className='hide-mobile creator_list '>
                        {creators.map(
                            (creator, index)=>{
                                return(
                                    <div className="creator_box" key={index}>
                                        <img src={"/img/players/creators/"+creator.img} alt={creator.alt}/>
                                    </div>
                                );
                            }) 
                        }
                    </div>
                <div className='show-mobile carousel-mobile'>
                    <Slider
                        dots={true}
                        infinite={true}
                        arrows={false}
                        variableWidth={false}

                    >
                            {creators.map((creator, index) => {
                                return (
                                    <div key={index}>
                                        <img src={"/img/players/creators/" + creator.img} alt={creator.alt} />
                                    </div>
                                );
                            })}
                    </Slider>
                </div>
                </div>
            </section>
            <section id='la-pausa-che-non-lagga-wrap' style={{ backgroundImage: 'url(/img/players/sfondi/forma-2.png)' }}>
                <div id='la-pausa-che-non-lagga' className='container'>
                    <div id='la-pausa-che-non-lagga-row'>
                        <div id='la-pausa-che-non-lagga-left' style={{ backgroundImage: 'url(/img/players/sfondi/tablet.png)' }}>
                            <video controls>
                                <source src="/video/video_players-1.mp4" type="video/mp4" />
                            </video>
                        </div>
                        <div id='la-pausa-che-non-lagga-right'>
                            <div className='lagga-box-text'>
                                <img src='/img/players/elementi/la-pausa-che-non-lagga.png' id="lagga-box-logo-mobile" />
                                
                                <p>
                                    Gaming, realtà aumentata, sfide on-line infinite: dal 2021, con La pausa che non lagga, Mr.Day è diventato il break che ti ricarica durante le partite, grazie anche a dei compagni di gioco davvero speciali.
                                </p>
                            </div>
                            
                            <div className='lagga-box-logo'>
                                <img src='/img/players/elementi/la-pausa-che-non-lagga.png'/>
                            </div>
                            {gallery_1.map(
                                (img, index) => {
                                    return (
                                        <div className="lagga-box-img" key={index}>
                                            <img src={"/img/players/gallery/" + img.img} alt={img.alt} />
                                        </div>
                                    );
                                })
                            }
                            <div className='show-mobile carousel-mobile'>
                            <Slider
                                dots={true}
                                infinite={true}
                                arrows={false}
                                variableWidth={false}

                            >
                                {gallery_1.map((creator, index) => {
                                    return (
                                        <div key={index}>
                                            <img src={"/img/players/gallery/" + creator.img} alt={creator.alt} />
                                        </div>
                                    );
                                })}
                                
                            </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="secret-match" style={{ backgroundImage: 'url(/img/players/elementi/bocca.png)' }}> 
                <div className="container">
                    <div id='secret-match-left'>
                        <div className='secret-match-text'>
                            Grazie al level up del 2022, La pausa che non lagga è diventata…“The Secret Match”!<br />
                            Un evento segreto e imperdibile su Twitch solo per veri gamer, con BAZY GANG, i PlayerInside e Seleyes.
                        </div>
                        <div className='secret-match-baloon'>
                            <img src='/img/players/elementi/thesecretmatch-logo.svg' />
                        </div>
                        {gallery_2.map(
                            (img, index) => {
                                return (
                                    <div className="secret-match-block  hide-mobile" key={index}>
                                        <img src={"/img/players/gallery/" + img.img} alt={img.alt} />
                                    </div>
                                );
                            })
                        }
                        
                        
                    </div>
                    <div id='secret-match-right'>
                        
                       
                        
                        <div id='secretmatch-video'>
                            <div id='secretmatch-video-wrap' style={{ backgroundImage: 'url(/img/players/sfondi/iphone-2.png)' }}>
                            
                            <video controls>
                                <source src="/video/video_players-2.mp4" type="video/mp4" />
                            </video>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                </section>
            
                <section id='player-slider'>
                    <div className='container'>
                    <div id='secret-match-text-mobile'>
                        <img src='/img/players/elementi/thesecretmatch-logo.svg' />
                        Grazie al level up del 2022, La pausa che non lagga è diventata…“The Secret Match”!<br />
                        Un evento segreto e imperdibile su Twitch solo per veri gamer, con BAZY GANG, i PlayerInside e Seleyes.

                    </div>
                <div className='show-mobile carousel-mobile'>
                    
                    <Slider
                        dots={true}
                        infinite={true}
                        arrows={false}
                        variableWidth={false}

                    >
                            {gallery_2.map((creator, index) => {
                                return (
                                    <div key={index}>
                                        <img src={"/img/players/gallery/" + creator.img} alt={creator.alt} />
                                    </div>
                                );
                            })}
                        
                    </Slider>
                    </div></div>
            </section>

            <section id='vinci-un-giorno-da-mates' style={{ backgroundImage: 'url(/img/players/sfondi/sfondo-glitch.png),  linear-gradient(#F49D00 0%, #F49D00 50%, white 50.1%, white 100%)' }}>
                <div className='container'>
                    <div className='mates-left'>
                        <img src='/img/players/mates/mrday-cup.png' />
                        <img src='/img/players/mates/live-su-twitch.png' />
                    </div>
                    <div id='mates-video' style={{ backgroundImage: 'url(/img/players/sfondi/mates-bg.png)' }}>
                        <div id='live-su-twitch'>

                        </div>
                        <div id='mates-video-wrap' style={{ backgroundImage: 'url(/img/players/sfondi/iphone-1.png)' }}>
                            <video controls>
                                <source src="/video/video_players-3.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    <div className='mates-right'>
                        <div id='mates-logo'>
                            <img src='/img/players/mates/vinci-mates.png' />
                        </div>
                        <div id='mates-text'>
                            Nel 2020, invece, con i Mates abbiamo organizzato
                            la Mr.Day Cup: abbiamo riunito i top gamer in una sfida nei videogiochi più amati, dando la possibilità
                            ai follower di sfidare i loro giocatori preferiti
                            e di vincere una giornata assieme ai loro youtuber preferiti.
                        </div>
                        <div className='mates-img-block'>
                            <img src='/img/players/mates/vinci-mates-01.jpg' />
                        </div>
                        <div className='mates-img-block'>
                            <img src='/img/players/mates/vinci-mates-02.jpg' />
                        </div>
                    </div>
                </div>
            </section>
            <section id='backto'>
                <a href='/'>
                    <img src='/img/players/elementi/big_arrow.png'></img>
                    TORNA ALLA HOME
                </a>
            </section>
            <Footer />
        </div>
    );

}
export default Players;